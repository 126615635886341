<template>
  <div id="email-list">
    <v-card>
      <v-card-title>会員一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="mbr_sei_kana"
            placeholder="セイ"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="changeSeiKana"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="mbr_mei_kana"
            placeholder="メイ"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="changeMeiKana"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="telephoneNum"
            placeholder="電話番号"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            @change="changeTelephoneNum"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="mbr_status"
            :items="MemberStatusMaster"
            placeholder="会員状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'member-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="memberList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.mbr_status`]="{ item }">
          <div>
            {{ getStatus(item.mbr_status) }}
          </div>
        </template>
        <!-- <template v-slot:[`item.img`]="{ item }">
          <div class="p-2">
            <v-img
              :src="item.img ? item.img : require('@/assets/images/noimage.jpg')"
              max-height="50"
              max-width="50"
              class="me-3"
            ></v-img>
          </div>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col
              cols="12"
            >
              <v-icon
                small
                @click="editMember(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiPencil,
  mdiExportVariant,
  mdiPlus,
} from '@mdi/js'

export default {
  components: {
  },
  data: () => ({
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    mbr_status: '',
    telephoneNum: undefined,
    mbr_mei_kana: undefined,
    mbr_sei_kana: undefined,
    search: '',
    icons: {
      mdiPencil,
      mdiExportVariant,
      mdiPlus,
    },
    headers: [
      {
        text: '会員ID',
        value: 'mbr_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '会員コード',
        value: 'mbr_code',
        align: 'left',
        width: '11%',
        fixed: true,
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: '名',
        align: 'left',
        sortable: false,
        value: 'mbr_mei',
        width: '8%',
        fixed: true,
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        width: '8%',
        align: 'left',
        sortable: false,
        fixed: false,
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        width: '8%',
        align: 'left',
        sortable: false,
        fixed: true,
      },
      {
        text: 'メールアドレス',
        value: 'mbr_email',
        width: '17%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '電話番号',
        value: 'mbr_mobile',
        width: '17%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '会員状態',
        value: 'mbr_status',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'center',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('memberStore', ['memberList', 'MemberStatusMaster']),
    getStatus() {
      return function (status) {
        const Statuses = this.MemberStatusMaster.find(ms => ms.value === status)

        return Statuses ? Statuses.text.slice(3) : ''
      }
    },
  },
  watch: {
    mbr_status(newValue) {
      console.log('newValue:', newValue)

      this.changeRouteQuery('mbr_status', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapActions('memberStore', ['loadMemberList', 'loadMemberStatusList']),
    ...mapMutations('app', ['setOverlayStatus']),
    changeSeiKana() {
      this.changeRouteQuery('mbr_sei_kana', this.mbr_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('mbr_mei_kana', this.mbr_mei_kana)
      this.loadData()
    },
    changeTelephoneNum() {
      this.changeRouteQuery('mbr_mobile', this.telephoneNum)
      this.loadData()
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadMemberStatusList(),
        this.loadMemberList(this.$route.query),
      ]).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    editMember(member) {
      this.$router.push({
        name: 'member-edit',
        params: {
          id: member.mbr_id, query: this.$route.query,
        },
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val || val === 0) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.mbr_sei_kana = this.$route.query.mbr_sei_kana ?? ''
      this.mbr_mei_kana = this.$route.query.mbr_mei_kana ?? ''
      this.telephoneNum = this.$route.query.mbr_mobile ?? ''
      // eslint-disable-next-line radix
      this.mbr_status = this.$route.query.mbr_status ? parseInt(this.$route.query.mbr_status) : ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
